<template>
  <div style="margin: -15px -15px">
    <div class="DrugCont-top">
      <div class="top_cont">
        <div class="DrugCont-top-input">
          <span>名称：</span>
          <a-input-search
            placeholder="请输入"
            enter-button="查询"
            @search="onSearch"
            style="width: 300px"
            allowClear
            v-model="title"
          />
        </div>
      </div>
    </div>

    <div style="margin-top: 10px">
      <a-button type="primary" @click="add()" style="margin: 0px 0px 10px 20px">
        +添加
      </a-button>
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="dataSource"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <span slot="NUM" slot-scope="text, item, index">
          {{ index + (curPage - 1) * pageSize + 1 }}
        </span>
        <span slot="is_show" slot-scope="text, item, index">
          <a-switch
            v-model="item.isShow !== 0"
            checked-children="开"
            un-checked-children="关"
            @click="switchOpen(item.id, item.isShow === 1 ? 0 : 1, item)"
            default-checked
          />
        </span>
        <span slot="operation" slot-scope="text, item, index">
          <a @click="edit(item)">编辑</a>&ensp;
          <a-popconfirm
            title="是否确定删除?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="del(item.id)"
            @cancel="delCancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ curPage }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="curPage"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
    <!-- 添加弹窗 -->
    <a-modal
      v-model="show"
      :title="titleModal"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
        :rules="rules"
        ref="form"
        :model="addForm"
      >
        <a-form-model-item label="制定者名称" prop="name">
          <a-input v-model="addForm.name" placeholder="请输入"> </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getMaker,
  delMaker,
  putMaker,
  postMaker,
} from "../../../service/document_api";
export default {
  name: "maker",
  data() {
    return {
      titleModal: "新增制定者",
      title: "",
      total: 0,
      curPage: 1,
      pageSize: 10,
      dataSource: [],
      addForm: {},
      show: false,
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "NUM" },
          dataIndex: "num",
          width: "7%",
          align: "center",
        },
        {
          title: "制定者名称",
          dataIndex: "name",
          key: "name",
          align: "center",
          width: "20%",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          key: "createdTime",
          align: "center",
          width: "16%",
        },
        {
          title: "创建人",
          dataIndex: "userName",
          key: "userName",
          align: "center",
          width: "16%",
        },
        {
          title: "是否展示",
          dataIndex: "is_show",
          key: "is_show",
          align: "center",
          width: "16%",
          scopedSlots: { customRender: "is_show" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
          width: "16%",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await getMaker(this.title, this.curPage, this.pageSize);
      if (res.code == 0) {
        this.dataSource = res.data;
        this.total = res.count;
      }
    },
    onSearch() {
      this.curPage = 1;
      this.getList();
    },
    // 分页查询
    pageChange(curPage, pageSize) {
      this.curPage = curPage;
      this.pageSize = pageSize;
      this.getList();
    },
    add() {
      this.show = true;
    },
    handleCancel() {
      this.show = false;
      this.addForm = {};
    },
    async handleOk() {
      this.getList();
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.titleModal == "新增制定者") {
            let data = {
              name: this.addForm.name,
              isShow: 1,
            };
            const res = await postMaker(data);
            if (res.code == 0) {
              this.getList();
              this.show = false;
              this.addForm = {};
              this.$message.success("添加成功！");
              this.titleModal = "新增制定者";
            }
          } else {
            let data = {
              id: this.addForm.id,
              name: this.addForm.name,
              isShow: this.addForm.isShow,
            };
            const res = await putMaker(data);
            if (res.code == 0) {
              this.getList();
              this.show = false;
              this.addForm = {};
              this.$message.success("编辑成功！");
              this.titleModal = "新增制定者";
            }
          }
        }
      });
    },
    edit(item) {
      this.addForm = item;
      this.titleModal = "修改制定者";
      this.show = true;
      this.getList();
    },
    async del(id) {
      const res = await delMaker(id);
      if (res.code == 0) {
        this.getList();
        this.$message.success("删除成功！");
      }
    },
    delCancel() {},
    async switchOpen(id, isShow, item) {
      let data = {
        id: id,
        isShow: isShow,
        name: item.name,
      };
      const res = await putMaker(data);
      if (res.code == 0) {
        this.getList();
        this.$message.success("修改成功");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.DrugCont-top {
  align-items: center;
  height: 100px;
  border-bottom: 15px solid #f0f2f5;
  .top_cont {
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .DrugCont-top-input {
    height: 50px;
    line-height: 30px;
    padding-top: 30px;
  }
}
</style>
